import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { AnimatedLink } from '@styles/globalComponents'

const PageAbout = () => {
  return (
    <Aside>
      <Container>
        <Link to="/about">
          <StaticImage
            src="../../static/images/olivia-rose-about-side.jpg"
            alt="About Olivia Rose"
            placeholder="blurred"
            objectFit="cover"
          />
        </Link>
        <Title>Hi, I'm Olivia</Title>
        <Description>
          We love to eat, travel, cook, and eat some more! We create &
          photograph deliciously decedant recipes from our home in Big Island
          Hawaii, while our golden retriever pups eat the kale stems that fall
          on the kitchen floor.
        </Description>
        <AnimatedLink>
          <Link to="/about">More</Link>
        </AnimatedLink>
      </Container>
    </Aside>
  )
}

const Aside = styled.aside`
  max-width: 400px;
  margin: 2em auto;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    max-width: 280px;
    grid-column: 2;
    grid-row: 1 / span 2;
  }
`

const Container = styled.div`
  text-align: center;
  line-height: 1.6;
`

const Title = styled.h3`
  padding: 0.5em;
`

const Description = styled.p`
  // padding: 0.5em;
  font-size: 0.85em;
`

export default PageAbout
