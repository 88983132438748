import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import { Section } from '@styles/globalComponents'
import PageBody from '@components/PageBody'
import TagList from '@components/TagList'
import PostLinks from '@components/PostLinks'
import PostDetails from '@components/PostDetails'
import PageRecipe from '@components/PageRecipe'
import SEO from '@components/SEO'
import Comments from '@components/Comments'
import PageAbout from '@components/PageAbout'
import CategoryPosts from '@components/CategoryPosts'
import ShareButtons from '@components/ShareButtons'
import LogoContainer from '@components/Logo'

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    serving,
    prepTime,
    cookTime,
    metaDescription,
    heroImage,
    body,
    recipe,
    publishDate,
    tags,
    childContentfulRecipePostMetaDescriptionTextNode,
  } = data.contentfulRecipePost
  const similarPosts = data.similarPosts.edges

  const { prev, next, basePath, slug } = pageContext

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <PostLogoWrapper />
      <HeroSection>
        <Hero title={title} image={heroImage} date={publishDate} />
        <PostDetails
          date={publishDate}
          timeToRead={body.childMarkdownRemark.timeToRead}
          title={title}
          metaDescription={
            childContentfulRecipePostMetaDescriptionTextNode.metaDescription
          }
          serving={serving}
          prepTime={prepTime}
          cookTime={cookTime}
        />
      </HeroSection>
      <PostSection>
        <PageBody body={body} />
        <PageRecipe recipe={recipe} title={title} />
        <ShareButtons title={title} />
        {tags && <TagList tags={tags} basePath={basePath} />}
        <PostLinks previous={prev} next={next} basePath={basePath} />
        <Comments slug={slug} commentsData={data.comments} title={title} />
        <PageAbout />
      </PostSection>
      <CategoryPosts
        category="Similar Articles"
        posts={similarPosts}
        basePath={basePath}
      />
    </Layout>
  )
}

const HeroSection = styled.section`
  position: relative;
  min-height: 300px;

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4rem;
    margin-top: 4rem;
  }
`

const PostSection = styled(Section)`
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    display: grid;
    column-gap: 3rem;
  }
`

const PostLogoWrapper = styled(LogoContainer)`
  @media (max-width: ${(props) => props.theme.screen.medium}) {
    display: none;
  }
`

export const query = graphql`
  query($slug: String!) {
    contentfulRecipePost(slug: { eq: $slug }) {
      title
      slug
      serving
      prepTime
      cookTime
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      childContentfulRecipePostMetaDescriptionTextNode {
        metaDescription
      }
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        gatsbyImageData(width: 1200, quality: 100, layout: CONSTRAINED)
        ogimg: resize(width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 120)
        }
      }
      recipe {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
    comments: allCommentsYaml(
      filter: { slug: { eq: $slug } }
      sort: { fields: [date], order: ASC }
    ) {
      edges {
        node {
          _id
          name
          email
          date(formatString: "MMMM DD, YYYY")
          message
          rating
          reply_to
        }
      }
      totalCount
    }
    similarPosts: allContentfulRecipePost(
      filter: { tags: { elemMatch: { title: { eq: "Dessert" } } } }
      limit: 3
    ) {
      edges {
        node {
          ...Content
        }
      }
    }
  }
`

export default PostTemplate
